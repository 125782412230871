import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import Form from "../components/form"
import ScrollAnimation from "react-animate-on-scroll"

import { FormPageStyle } from "../styles/orcamento"

const Orcamento = props => {
  const { contactPage2 } = props.data.dataJson || {}

  return (
    <>
      <SEO
        title="Orçamento"
        image="/images/orcamentos.jpg"
        imageWidth="1200"
        imageHeight="628"
        pathname="/orcamentos"
        description="Contacte a Sicarpo e exponha o seu projeto para que possa ser analisado e orçamentado."
      />
      {props.data && props.data.dataJson ? (
        <FormPageStyle>
          <div
            className="startArea"
            style={{ backgroundImage: `url(${contactPage2.img.publicURL})` }}
          >
            <div className="container">
              <div className="left">
                <ScrollAnimation
                  animateIn="fadeInDown"
                  offset={0}
                  duration={1.2}
                  animateOnce={true}
                >
                  <h1 className="title">{contactPage2.title}</h1>
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="fadeInDown"
                  offset={0}
                  duration={1.2}
                  animateOnce={true}
                >
                  <p className="text">{contactPage2.text}</p>
                </ScrollAnimation>
              </div>
              <div className="right">
                <Form formType="orcamento" />
              </div>
            </div>
          </div>
        </FormPageStyle>
      ) : (
        <div className="isLoading">A carregar...</div>
      )}
    </>
  )
}

export default Orcamento

export const OrcamentoQuery = graphql`
  query OrcamentoQuery {
    dataJson {
      contactPage2 {
        img {
          publicURL
        }
        title
        text
      }
    }
  }
`
